'use strict';

document.addEventListener('DOMContentLoaded', () => {
    main();
});

function main() {
    setupMenu();
    setupNavs();
    setupAds();
}

function setupMenu() {
    const menu = document.getElementById("menu-container");
    const menuButton = document.getElementById("menu-button");
    const menuCloseButton = document.getElementById("menu-close-button");
    
    menuButton.addEventListener('click', () => { menu.classList.add('open')});
    menuCloseButton.addEventListener('click', () => { menu.classList.remove('open')});
}

function createElement(html) {
    const template = document.createElement('template');
    template.innerHTML = html;
    return template.content.firstChild;
}

function setupNavs() {
    const navs = document.querySelectorAll(".vscroll-nav, .hscroll-nav");

    navs.forEach((parent) => {
        const uls = parent.querySelectorAll("ul");

        const startButton = createElement("<button class='scroll-start-btn scroll-btn' title='Terug'></button>");
        const endButton = createElement("<button class='scroll-end-btn scroll-btn' title='Verder'></button>");

        startButton.addEventListener("click", function () {
            uls.forEach((ul) =>{
                ul.scrollLeft -= ul.clientWidth;
                ul.scrollTop -= ul.clientHeight;
            });
        });

        endButton.addEventListener("click", function () {
            uls.forEach((ul) =>{
                ul.scrollLeft += ul.clientWidth;
                ul.scrollTop += ul.clientHeight;
            });
        });

        parent.append(startButton);
        parent.append(endButton);

        uls.forEach((ul) =>{
            checkOverflow(ul);

            ul.addEventListener("scroll", function () {
                checkOverflow(ul);
            });

            ul.addEventListener("size", function () {
                checkOverflow(ul);
            });
        });

        window.addEventListener("resize", function () {
            uls.forEach((ul) =>{
                checkOverflow(ul);
            });
        });

        parent.addEventListener("size", function () {
            uls.forEach((ul) =>{
                checkOverflow(ul);
            });
        });

        function checkOverflow(ul) {
            if (ul.scrollHeight > ul.clientHeight ||
                ul.scrollWidth > ul.clientWidth) {
                parent.classList.add("has-overflow");
            }
            else {
                parent.classList.remove("has-overflow");
            }

            if (Math.floor(ul.scrollTop) > 0 ||
                Math.floor(ul.scrollLeft) > 0) {
                parent.classList.add("scroll-start");
            }
            else {
                parent.classList.remove("scroll-start");
            }

            if (Math.ceil(ul.scrollTop + ul.clientHeight) < ul.scrollHeight ||
                Math.ceil(ul.scrollLeft + ul.clientWidth) < ul.scrollWidth) {
                parent.classList.add("scroll-end");
            }
            else {
                parent.classList.remove("scroll-end");
            }
        }
    });
}

let adleave = 0;

function setupAds() {
    const textAds = document.querySelectorAll('.ad');
    
    textAds.forEach((curAd) => {
        curAd.addEventListener('mouseenter', function() {
            if (adleave !== 0) {
                clearTimeout(adleave);
            }

            curAd.classList.remove("faded");

            textAds.forEach((ad) => {
                if (curAd !== ad) {
                    ad.classList.add("faded");
                }
            });
        });

        curAd.addEventListener('mouseleave',
            function () {
                adleave = setTimeout(function () {
                    textAds.forEach((ad) => {
                        ad.classList.remove("faded");
                    });
                }, 300);
            });
    });
}
